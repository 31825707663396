::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-thumb {
  background: rgb(60, 60, 60, 0.5);
}

::-webkit-scrollbar-thumb:hover {
  background: rgb(60, 60, 60);
}

.big-container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.margined-container {
  margin-top: 50px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 22px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 2px;
  top: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #2196f3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.slider.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}

#available-div {
  text-align: center;
}

#available-span {
  margin-right: 10px;
}

.icon-container {
  position: relative;
}

.icon {
  position: absolute;
  padding: 10px;
  margin-left: 90%;
  color: grey;
  cursor: pointer;
  z-index: 2;
}

.full-input,
.full-textarea {
  border-radius: 5px;
  width: 100%;
  outline: none;
  border: 1px solid #d8d8d8;
}

.full-input {
  height: 40px;
}

.full-textarea {
  height: 80px;
}

.full-input:focus,
.full-textarea:focus {
  outline: 3px solid #3399ff;
  border: 0;
}

.big-form {
  text-align: left;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  border-left: 1px solid #d8d8d8;
  border-right: 1px solid #d8d8d8;
}

.small-form {
  margin-left: auto;
  margin-right: auto;
  width: 35%;
  text-align: left;
}

.top-form {
  margin-top: 10%;
}

.note-parag {
  color: rgba(0, 0, 0, 0.65);
  font-size: 15px;
}

.error-parag {
  color: red;
  font-size: 15px;
  margin-bottom: 0;
}

.centered-parag {
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.65);
}

.big-font {
  font-size: 20px;
}

.small-select {
  width: 20%;
  background-color: white;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
}

.frames {
  position: absolute;
  height: 800px;
  width: 100%;
  left: 0;
  overflow: auto;
}

.centered-select {
  width: 20%;
}

.custom-alert {
  margin: 0 20px;
  background-color: rgb(85, 85, 85);
  color: white;
  border-radius: 3px;
  width: fit-content;
  text-align: center;
}

.color-pick {
  float: right;
  margin-left: 5px;
  height: 20px;
  width: 20px;
  border-radius: 50%;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.normal-table {
  margin-left: auto;
  margin-right: auto;
}

.normal-table thead tr {
  background-color: #015c9a;
  color: white;
}

.normal-table tr:nth-child(even) {
  background-color: #d2dee5;
}

.normal-table tr {
  border-bottom: 1px solid #f5f5dc;
}

.normal-table tr th {
  font-weight: 500;
}

.normal-table td,
.normal-table th {
  padding: 10px;
  text-align: center;
}

.scrollable-table thead {
  position: sticky;
  z-index: 2;
  top: 0px;
}

.linkable-table tbody tr {
  cursor: pointer;
}

.table-wrapper {
  height: 700px;
  overflow-y: scroll;
  overflow-x: scroll;
}
.vertical-table-wrapper {
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: scroll;
  margin-left: auto;
  margin-right: auto;
}

.fitting-table-wrapper {
  width: fit-content;
  margin-right: auto;
  margin-left: auto;
}

.trash-icon,
.edit-icon,
.plus-icon,
.eye-icon,
.calculator-icon,
.file-icon {
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
}

.eye-icon {
  color: #007fff;
}

.trash-icon {
  color: red;
}

.file-icon {
  color: #007fff;
}

.calculator-icon {
  color: #ccc;
}

.sort-icon {
  color: white;
}

.sort-icon:hover {
  color: #3399ff;
  cursor: pointer;
}

.img-icon {
  height: 20px;
  width: 20px;
}

.nawy-btn {
  background-color: #015c9a;
  margin-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
  margin-bottom: 10px;
  height: 40px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.link-button {
  background: none;
  border: none;
  padding: 0;
  font-family: arial, sans-serif;
  color: #069;
  cursor: pointer;
}

.link-button:hover {
  text-decoration: underline;
}

.users-div {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.left-aligned {
  text-align: left;
}

.primary-info {
  display: inline;
  font-weight: bold;
}

.secondary-info {
  display: inline;
  margin-left: 10px;
  font-weight: normal;
  color: #a8a8a8;
}

.bold-span {
  font-weight: bold;
}

.light-span {
  color: #a8a8a8;
}

.left-list {
  padding-left: 0;
}

.no-style-list {
  list-style-type: none;
}

.delete-span {
  cursor: pointer;
  font-size: 13px;
  margin-right: 2px;
}

#filters-div {
  text-align: left;
}

#close-filters {
  visibility: hidden;
}

.mobile-element {
  display: none;
}

#open-filters,
#done-btn {
  display: none;
}

.gauge-details {
  font-size: 16px;
  font-weight: 600;
  fill: rgb(40, 44, 52);
  text-align: left;
  border-radius: 5px;
  border: 1px solid black;
}

.target {
  background-color: #21864b;
}
.achieved {
  background-color: #ffff00;
}

.reserved {
  background-color: #f85964;
}
.gauge-details {
  background-color: #d2dee5;
}

#eoi-div {
  background-color: #c6de17;
}

.details-div {
  background-color: white;
}

.gauge-details div {
  padding: 5px;
  color: black;
}

.gauge-details div span:nth-of-type(1) {
  padding-left: 5px;
}

.gauge-details div span:nth-of-type(2) {
  padding-right: 5px;
  float: right;
}

.full-btn {
  width: 100%;
}

#leads-btn-container {
  text-align: left;
}

.btn-div {
  display: inline;
}

.lead-page-btn {
  margin-right: 10px;
}

#lead-details-btn-container {
  text-align: right;
}

.lead-details-btn {
  margin-left: 10px;
}

.details-div {
  background-color: white;
  text-align: center;
}

.details-div {
  text-align: center;
}

#target-details {
  background-color: white;
}

#per_page {
  height: 40px;
}

#table-options {
  cursor: pointer;
  color: white;
}

#table-options:hover {
  color: red;
}

.sortable-column {
  margin-right: 5px;
}

.picker-wrapper {
  position: relative;
}

.picker-div {
  position: absolute;
  top: 0;
  z-index: 10;
}

.lead-info {
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  text-align: left;
  background-color: #f8f8f8;
  width: 94%;
  margin-left: 3%;
  margin-right: 3%;
}

.lead-inner-div {
  padding-top: 10px;
  padding-left: 10px;
}

.inner-div {
  width: 92%;
  margin-left: auto;
  margin-right: auto;
}

#merged-div {
  background-color: rgb(255, 0, 56, 0.7);
}

#merge-btn {
  background-color: #eee9e9;
  color: black;
  width: 100px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
  outline: none;
}

#compound-link-btn {
  color: #2196f3;
}

.grey-span {
  color: #595959;
}

.small-span {
  font-size: 15px;
}

.big-span {
  font-size: 18px;
}

#compound-span {
  font-size: 25px;
  max-width: 65%;
}

.inline-div {
  display: inline;
}

.block-div {
  display: block;
}

.report-an-issue {
  color: #ef5f5f;
  font-size: 12px;
  cursor: pointer;
  float: right;
}

.report-an-issue:hover {
  text-decoration: underline;
}

#master-plan {
  width: 100%;
  height: 120px;
  cursor: pointer;
}

#compound-details {
  min-height: 500px;
}

.list-table {
  border: 1px solid #d3d3d3;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 0px;
}
.list-table tr {
  font-size: 14px;
  height: 24px;
}
.plan table tr td {
  padding: 0;
}

.dark {
  background-color: #e9e9e9;
}

.light {
  background-color: white;
}

.left-col {
  width: 35%;
  padding-left: 10px;
}

.right-col {
  width: 65%;
  text-align: left;
}

.centered-div {
  text-align: center;
}

#expanded-master-plan {
  position: fixed;
  z-index: 3;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  overflow: hidden;
}

.full-image {
  width: 100%;
  height: 100%;
}

.close-button {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 4;
}

#comments_submit {
  margin-top: 20px;
}

.border-bottom-div {
  border-bottom: 1px solid #d0d0d0;
}

#comments-wrapper {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}

.comment-div {
  border: 2px solid black;
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
}

.colored-span {
  padding: 5px;
  margin-left: 3px;
  margin-right: 3px;
  border-radius: 5px;
}

.status-change {
  font-weight: bold;
  background-color: #add8e6;
  padding: 10px;
  border-radius: 5px;
  margin: 8px 0;
  display: inline-block;
}

.preformatted-p {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  border: none;
  background-color: white;
  font-size: 14px;
  padding: 10px 0;
  white-space: pre-wrap;
  word-break: normal;
}

.colored-comment-div {
  background-color: #add8e6;
  padding: 10px;
  border-radius: 5px;
  font-weight: bold;
  margin-bottom: 15px;
}

.wrapping-div {
  width: fit-content;
}

.commenter-name {
  color: #0000ff;
  font-weight: bold;
}

.lead-infos-div {
  padding: 10px;
  background-color: #f8f8f8;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
}

.info-div {
  padding: 10px;
}

.wide-cell {
  width: 300px;
}

#history-wrapper {
  max-height: 500px;
  overflow-y: scroll;
}

.sale-button {
  display: block;
  color: black;
  border-radius: 5px;
}

.blue-editable-div {
  color: #069;
  font-size: 15px;
}

.large-editable-div {
  color: black;
  padding: 5px;
  border-radius: 5px;
}

.comment-date-div {
  color: #595959;
  font-size: 12px;
  margin-left: 5px;
}

.mandatory-span {
  color: red;
}

.user-color-picker {
  position: absolute;
  z-index: 10;
  top: 30px;
}

.status-color-picker {
  position: absolute;
  top: 30px;
  z-index: 2;
  left: -65px;
}

.comment-parag {
  white-space: pre-line;
  max-width: 100%;
}

#lead-header {
  text-align: left;
}

#search-btn {
  display: block;
  margin-bottom: 10px;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}

#lead-error {
  position: absolute;
  top: 50%;
  width: 80%;
  margin-left: 10%;
  z-index: 2;
  font-size: large;
}

.close-error {
  cursor: pointer;
}

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.action-btn {
  padding: 8px;
  border: 1px solid black;
  border-radius: 2px;
  cursor: pointer;
}

.confirm-btn {
  background-color: deepskyblue;
  margin-left: 5px;
}

.cancel-btn {
  background-color: firebrick;
}

.spaced-span {
  margin-left: 10px;
}

#react-org-chart {
  margin-top: 0%;
  cursor: move;
  height: 100vh;
  width: 100%;
  background-color: #f7f9fa;
  overflow: hidden;
}
.org-chart-person-name {
  font-weight: 500;
}
.org-chart-person-link:hover g {
  fill: #409cf9 !important;
}
.org-chart-node:hover .org-chart-person-reports {
  fill: #409cf9 !important;
}
.org-chart-person-dept.engineering {
  fill: #4caf50 !important;
}
.org-chart-person-dept.communications {
  fill: #3f51b5 !important;
}
.org-chart-person-dept.product {
  fill: #d500f9 !important;
}
.org-chart-person-dept.hr {
  fill: #2196f3 !important;
}
.org-chart-person-dept.marketing {
  fill: #f44336 !important;
}
.org-chart-person-dept.design {
  fill: #26c6da !important;
}

.zoom-buttons {
  margin-top: 10%;
  position: absolute;
  left: 0;
}

#search-tree-div {
  margin-top: 5%;
  position: absolute;
  left: 5px;
  display: block !important;
  width: 20%;
}
.zoom-button {
  width: 40px;
  height: 40px;
  display: block !important;
  margin: 8px;
}

.btn {
  font-size: 0.875rem;
  text-transform: none;
  text-decoration-line: none;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
  margin: 6px;
}

.btn-outline-primary {
  color: #374ea2;
  border-color: #374ea2;
}

#react-org-chart {
  margin-top: 0%;
  cursor: move;
  height: 100vh;
  width: 100%;
  background-color: #f7f9fa;
  overflow: hidden;
}
.org-chart-person-name {
  font-weight: 500;
}
.org-chart-person-link:hover g {
  fill: #409cf9 !important;
}
.org-chart-node:hover .org-chart-person-reports {
  fill: #409cf9 !important;
}
.org-chart-person-dept.engineering {
  fill: #4caf50 !important;
}
.org-chart-person-dept.communications {
  fill: #3f51b5 !important;
}
.org-chart-person-dept.product {
  fill: #d500f9 !important;
}
.org-chart-person-dept.hr {
  fill: #2196f3 !important;
}
.org-chart-person-dept.marketing {
  fill: #f44336 !important;
}
.org-chart-person-dept.design {
  fill: #26c6da !important;
}

.zoom-buttons {
  margin-top: 10%;
  position: absolute;
  left: 0;
}

#search-tree-div {
  margin-top: 5%;
  position: absolute;
  left: 5px;
  display: block !important;
  width: 20%;
}
.zoom-button {
  width: 40px;
  height: 40px;
  display: block !important;
  margin: 8px;
}

.btn {
  font-size: 0.875rem;
  text-transform: none;
  text-decoration-line: none;
  display: inline-block;
  font-weight: 600;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  padding: 0.5rem 1rem;
  font-size: 0.875rem;
  line-height: 1.5rem;
  border-radius: 0.125rem;
  cursor: pointer;
  margin: 6px;
}

.btn-outline-primary {
  color: #374ea2;
  border-color: #374ea2;
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #374ea2;
  border-color: #374ea2;
}

@media only screen and (max-width: 775px) {
  #search-tree-div {
    margin-top: 5%;
    position: absolute;
    left: 5px;
    width: 40%;
  }
  .zoom-buttons {
    margin-top: 25%;
    position: absolute;
    left: 0;
  }

  .big-container {
    width: 100%;
    margin-right: 0;
    margin-left: 0;
  }

  .icon {
    margin-left: 85%;
  }

  .small-form {
    width: 90%;
  }

  .big-form {
    width: 100%;
    border: 0;
  }

  .check-label {
    font-size: 15px;
  }

  .centered-select {
    width: 100%;
  }

  .responsive-table thead {
    display: none;
  }

  .responsive-table,
  .responsive-table tbody,
  .responsive-table tr,
  .responsive-table td {
    display: block;
    width: 100%;
  }

  .responsive-table td,
  .responsive-table tr {
    border: 1px solid #eee9e9;
  }

  .responsive-table tr:nth-child(odd) {
    background-color: #ffffcc;
  }

  .responsive-table tr {
    margin-bottom: 15px;
  }

  .responsive-table td {
    text-align: right;
    position: relative;
  }

  .responsive-table td::before {
    content: attr(data-label);
    position: absolute;
    left: 0;
    width: 50%;
    text-align: left;
    padding-left: 10px;
  }

  .users-div {
    width: 100%;
    margin: 0;
  }

  .secondary-info {
    display: block;
    margin-left: 0;
  }

  .primary-info {
    display: block;
    margin-left: 0;
  }

  .user-info {
    text-align: left;
  }

  .margined-container {
    margin-top: 0;
  }

  #filters-div {
    padding-top: 0;
  }

  #filters-div {
    position: fixed;
    left: -100%;
    transition: 0.4s;
    background-color: #f0f0f0;
    height: calc(100% - 40px);
    overflow-y: scroll;
    z-index: 10;
  }

  #filters-wrapper {
    padding-top: 10px;
    position: absolute;
    top: 0;
    left: 0;
    width: 96%;
    margin-left: 2%;
  }

  #close-filters {
    visibility: visible;
    float: right;
  }

  .mobile-element {
    display: inline-block;
  }

  #open-filters,
  #done-btn {
    display: inline-block;
  }

  #done-btn {
    margin-left: 10px;
  }

  #open-filters {
    border-radius: 0%;
    margin-left: 0px;
    margin-top: 0px;
  }

  #lead-info {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }

  .lead-inner-div {
    width: 96%;
  }

  #comments_submit {
    margin-top: 5px;
  }

  .fitting-table-wrapper {
    width: 100%;
    overflow-x: scroll;
  }

  .btn-div {
    display: block;
  }

  .lead-page-btn,
  .lead-details-btn {
    width: 46%;
    margin-right: 2%;
    margin-left: 2%;
  }

  #lead-details-btn-container {
    text-align: left;
  }

  #lead-header {
    text-align: center;
  }

  #search-btn {
    display: none;
  }
}
.main {
  font-weight: bolder;
  font-size: 24px;
  padding-bottom: 5px;
}

.telephone {
  font-size: 16px;
}
.lead-telephones-div .ant-table-tbody > tr > td {
  padding: 0px !important;
  border: none !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 14px !important;
}
.cursor-pointer {
  cursor: pointer;
}
.listing-filter{
  padding: 10px;
}
.listing-table{
  padding: 10px;
}
.listing-search{
  display: flex;
  align-items: center;
  justify-content: center;
}
.listing-search input{
  border: 1px solid hsl(0, 0%, 80%);
    border-radius: 5px;
    padding: 10px 100px;
    text-align: center;
}
#unit_number_field{
  display: flex;
  flex-direction: column;
}
#unit_number_field input{
  height: 40px;
}
.arrows-div{
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}

.table-title{
  display: flex;
  flex-flow: row;
  place-content: center space-between;
  align-items: center;
  padding: 5px;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
}

.field-listing-label{
  width: max-content;
  padding: 10px;
}